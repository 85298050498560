import React from "react";
import Layout from "../components/General/Layout.component";
import { carrierPage } from "../statics/pageInfo.static";
import { genPageInfo } from "../utils/genPageInfo.util";

const karrier = () => {
  return <Layout>{genPageInfo(carrierPage)}</Layout>;
};

export default karrier;
